<template>
  <section>
    <v-alert outlined type="error" v-if="!user.raffle.qualified">
      <strong>Not </strong> yet qualified for e-raffle 
    </v-alert>
      <v-alert outlined type="success" v-else>
      <strong>Congratulation! </strong> you are qualified for e-raffle 
    </v-alert>
    <v-card flat>
      <v-card-text>
        <div class="body-2 font-weight-bold mx-1 my-2">Asynchronous Module Progress</div>
        <v-progress-linear
          :color="user.raffle.async.progress < 50 ? '#999' : 'success'"
          rounded
          height="22"
          :value="user.raffle.async.progress"
          stream
          dark
        >
          {{ Math.ceil(user.raffle.async.progress)}} %
        </v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card flat v-for="(item, i) in user.raffle.booths.exhibits" :key="i" class="my-2">
      <v-card-text>
        <div class="body-1 font-weight-bold mx-1 mb-5">
          {{i == 0 ? 'Gold' : i == 1 ? 'Sliver' : 'Bronze'}}
        </div>
        <div class="d-flex ">
          <div class="">
            <v-progress-circular
              :color="item.progress < 50 ? '#999' : 'success'"
              rounded
              :value="item.progress"
              stream
              dark
              size="70"
              width="8"
              class=" mx-auto"
            >
              {{ Math.ceil(item.progress)}} %
            </v-progress-circular>
          </div>
          <div class="ml-5">
            <v-card  v-for="(booth, j) in item.booths" 
            :key="j"
            class="my-1 pa-3 d-flex justify-space-between align-center">
              <div class="caption">{{booth.booth_name}}</div>
              <v-chip class="ml-5" small color="info">
                {{booth.score}}
              </v-chip>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  props: ['user']
}
</script>

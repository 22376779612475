<template>
  <section>
    <v-row v-if="user.level == 1 || user.level == 2">
      <v-col cols="12" lg="9" class="">
        
        <v-card flat background-color="transparent">
          <v-row justify="center">
            <v-col cols="12" xl="8" lg="6">
              <Board :leaderboards="leaderboards"/>
            </v-col>
            <v-col cols="12" xl="4" lg="6">
              <MyRank :data="user"/>
            </v-col>
          </v-row>
          <v-overlay
            absolute
            :value="user.disqualified"
            opacity=".9"
          >
            <div class="text-center body-1">
              Your account has been flagged for sending spam messages. <br> Your account has been disqualified from the leaderboard contest.
            </div>
          </v-overlay>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <Raffle :user="user"/>
      </v-col>
    </v-row>
    <div class="" v-else>Gamification feature is exclusive for members and members-in-training</div>
    
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Raffle from '../components/gaming/Raffle.vue'
import Board from '../components/gaming/Leaderboard.vue'
import MyRank from '../components/gaming/MyRank.vue'

export default {
  components: {
    Raffle,
    Board,
    MyRank
  },
  data: () => ({

  }),
  computed: {
    ...mapState('student', {
      user: (state) => state.user,
      leaderboards: (state) => state.leaderboards,
    })
  },
  mounted() {
    this.getLeaderboard()
  },
  methods: {
    ...mapActions('student', [
      'getLeaderboard'
    ]),
  }
}
</script>
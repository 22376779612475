<template>
  <v-card flat>
    <div class="display-1 font-weight-bold pa-5">Leaderboard</div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-left">Image</th>
            <th class="text-left">Name</th>
            <th class="text-left">Points</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in leaderboards.data" :key="item.id" >
            <td width="5%" class="font-weight-bold">{{ i+=1 }}</td>
            <td width="10%">
              <v-avatar v-if="!item.user.image" size="35" color="secondary"/>
              <v-avatar v-else size="35" color="secondary">
                <v-img :src="`${item.user.aws}/users/${item.user.uuid}/image/${item.user.image.image}`"></v-img>
              </v-avatar>
            </td>
            <td class="caption">
              <div class="body-2 font-weight-bold">
                {{item.user.firstname + ' ' + item.user.lastname}}
              </div>
              <small>{{$helpers.level(item.user.level)}}</small>
            </td>
            <td width="20%" class="caption">{{item.total_score}} pts</td>
            
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  props: ['leaderboards']
}
</script>